.ezycompCalendar {
    min-width: 200px;
    max-width: 450px;
}

.ezycompCalendarHeader {
    color: var(--white);
    background-color: var(--royal-blue);
    width: 436px;
}

.ezycompCalendarHeader>span {
    cursor: pointer;
}

.ezycompMonthCalendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
}

.ezycompCalendarWeekDay {
    display: inline-flex;
    height: 48px;
    width: 48px;
    align-items: center;
    justify-content: center;
    padding: 6px;
    position: relative;
}

.ezycompCalendarWeekDay :global(span.dayBadge) {
    position: absolute;
    top: 0;
    height: 20px;
    right: 0;
    width: 20px;
    font-size: 10px;
    color: var(--white);
    font-weight: bold;
}

.ezycompCalendarMonthDay {
    display: inline-flex;
    height: 56px;
    width: 56px;
    align-items: center;
    justify-content: center;
    padding: 6px;
    position: relative;
}

.ezycompCalendarWeekDay span,
.ezycompCalendarMonthDay span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    cursor: pointer;
    border: 4px solid transparent;
}

.ezycompCalendarMonthDay :global(span.dayBadge) {
    position: absolute;
    top: 2px;
    height: 20px;
    right: 5px;
    width: 20px;
    font-size: 10px;
    color: var(--white);
    font-weight: bold;
}

.calendarTabs {
    border-bottom-width: 0px !important;
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
}

.calendarTab {
    border-radius: 25px 25px 0 0 !important;
    background-color: var(--gray) !important;
    color: var(--white) !important;
    padding: 10px 35px !important;
    font-weight: 500 !important;
    width: 100% !important;
    border-bottom-width: 0px !important;
}

.active {
    background-color: var(--royal-blue) !important;
    color: var(--white) !important;
    border: none !important;
    border-bottom-width: 0px !important;
    box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 6px 0 0 #1a406b, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
    position: relative;
}