.sideNav li {
    list-style: none;
}

.sideNav li button {
    background: transparent;
    border: none;
    width: 100%;
    padding: 0;
}

.sideNav li button:disabled {
    pointer-events: none;
}

.sideNav li button:disabled:hover {
    cursor: not-allowed !important;
}

.sideNav li a {
    display: flex;
    flex-direction: column;
    min-height: 40px;
    align-items: center;
}

/* .sideNav li a+.children {
    display: none !important;
} */

.sideNav li a+.children a {
    padding: 0px 15px 0 60px;
    margin: 3px 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    min-height: 30px;
}

.sideNav li a.active+.children {
    background-color: var(--blue) !important;
}
.sideNav li a:hover+.children {
    background-color: var(--medium-gray);
}

.sideNav li button:hover a+.children {
    background-color: var(--medium-gray);
}

.sideNav li a.active+.children a,
.sideNav li a:hover+.children a,
.sideNav li button:hover a+.children {
    color: var(--white) !important;
}

.sidenav-container.open .sideNav span.icon {
    display: inline-flex !important;
}
.sidenav-container:not([class*="open"]) .sideNav li:not(:first-of-type) {
    pointer-events: none;
}

.sideNav li a span.sidenav-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
}

.sideNav li a span.sidenav-item-icon svg {
    fill: inherit;
}

.sideNav a {
    display: block;
    padding: 5px 15px;
    color: var(--dark-gray);
}

.sideNav a.active {
    background-color: var(--royal-blue) !important;
    box-shadow: 0px 6px 9px -2px rgba(38, 57, 77, 0.8);
}

.sideNav a:hover{
    background-color: var(--gray-500);
    box-shadow: 0px 6px 9px -2px rgba(0, 0, 0, 0.3);
}

.sideNav li button:hover>a {
    background-color: var(--gray-500);
}

.sideNav a.active *,
.sideNav a:hover *,
.sideNav li button:hover>a {
    color: var(--white);
}

.sideNav li a.active span.sidenav-item-icon .btn-icon,
.sideNav li a:hover span.sidenav-item-icon .btn-icon {
    opacity: 1;
}

.sideNav li a.active span.sidenav-item-icon svg,
.sideNav li a:hover span.sidenav-item-icon svg {
    fill: var(--white);
}