.header-navbar-container .navbar {
    height: 84px;
    box-sizing: border-box;
}

.header-navbar-container .nav-item.dropdown .dropdown-toggle {
    height: 54px;
    display: flex;
    align-items: center;
    gap: 12px;
}

.header-navbar-container .nav-item.dropdown .dropdown-toggle .userNameL {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-nav .dropdown-menu {
    position: absolute !important;
}

.dropdown-item {
    cursor: pointer;
}

.last-login-time {
    font-size: 11px;
    text-align: center;
    font-weight: bold;
}

.notification-badge {
    position: absolute;
    bottom: 4px;
    width: 20px;
    height: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: var(--font-sm);
    font-weight: bold;
    background: var(--red);
    border-radius: 50%;
    color: var(--white);
    right: 10px;
}