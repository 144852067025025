.modal-dialog.alert-modal {
    max-width: 700px;
    padding: 30px;
}

.modal-dialog.alert-modal .modal-content {
    min-height: 440px;
    padding: 30px;
}

.modal-dialog.alert-modal .modal-content .modal-header {
    background-color: var(--white);
    color: var(--royal-blue);
    border-color: var(--royal-blue);
    border-radius: 20px 20px 0 0;
    text-align: center;
}

.modal-dialog.alert-modal .modal-header button.btn-close {
    margin-top: -4rem;
    margin-right: -1rem;
}

.modal-dialog.alert-modal .modal-title {
    font-size: var(--font-xxl);
    font-weight: 700;
}

.modal-dialog.alert-modal .modal-body {
    padding: 2rem var(--bs-modal-padding);
}

/* .modal-dialog.confirm-modal .modal-content {
    padding: 2rem;
} */

.modal-dialog.confirm-modal .modal-header {
    border-bottom: none;
}

/* .modal-dialog.confirm-modal .modal-header button.btn-close {
    margin-top: -4rem;
    margin-right: -1rem;
} */

.modal-dialog.confirm-modal .modal-footer {
    border-top: none;
}

.modal-dialog.drawer {
    position: fixed;
    top: 0;
    height: 100vh;
    margin: 0;
    right: 0;
    width: 90%;
    max-width: 500px;
    transform: none;
    translate: left .2s ease;
}

.modal-dialog.drawer .modal-content {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
}

.modal-dialog .modal-content .modal-header {
    background-color: var(--royal-blue);
    color: var(--white);
    border-radius: 0;
}
.modal-dialog.drawer .modal-content .modal-header {
    box-shadow: -4px 8px 8px rgba(0, 0, 0, 0.2) !important;
}

.modal-dialog.drawer .modal-content .modal-header,
.modal-dialog.drawer .modal-content .modal-body,
.modal-dialog.drawer .modal-content .modal-footer {
    padding: 1rem 2.5rem;
}

.modal-dialog .modal-content .modal-body {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
}

.modal-dialog.noFooter .modal-content .modal-body {
    max-height: calc(100vh - 70px) !important;
}

.modal-dialog:not([class*="alert-modal"]) .modal-header button.btn-close {
    color: var(--white);
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.2919 17.3954C19.5438 17.6472 19.6853 17.9888 19.6853 18.345C19.6853 18.7012 19.5438 19.0428 19.2919 19.2946C19.0401 19.5465 18.6985 19.6879 18.3423 19.6879C17.9861 19.6879 17.6446 19.5465 17.3927 19.2946L10.2997 12.1993L3.20442 19.2924C2.95257 19.5442 2.61099 19.6857 2.25482 19.6857C1.89864 19.6857 1.55706 19.5442 1.30521 19.2924C1.05335 19.0405 0.911865 18.6989 0.911865 18.3428C0.911865 17.9866 1.05335 17.645 1.30521 17.3932L8.40046 10.3001L1.30744 3.20487C1.05559 2.95302 0.9141 2.61143 0.9141 2.25526C0.9141 1.89909 1.05559 1.5575 1.30744 1.30565C1.55929 1.0538 1.90088 0.912311 2.25705 0.912311C2.61322 0.912311 2.95481 1.0538 3.20666 1.30565L10.2997 8.40091L17.3949 1.30453C17.6468 1.05268 17.9884 0.911194 18.3445 0.911194C18.7007 0.911194 19.0423 1.05268 19.2942 1.30453C19.546 1.55639 19.6875 1.89797 19.6875 2.25414C19.6875 2.61032 19.546 2.9519 19.2942 3.20375L12.1989 10.3001L19.2919 17.3954Z' fill='%23FFFFFF' /%3E%3C/svg%3E");
}

.modal-dialog.drawer .modal-header button.btn-close {
    margin-top: -2rem;
    margin-right: -2rem;
}