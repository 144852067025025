.qButtons {
    border: 1px solid var(--gray);
    border-radius: 1rem;
    box-sizing: border-box;
    width: 44px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qButtons:hover {
    background-color: var(--gray-800);
}

.qButtonSelected {
    border: 2px solid var(--royal-blue);
    font-weight: bold;
    color: var(--royal-blue);
}

.qButtonDisabled {
    opacity: 0.6;
    pointer-events: none;
}