.btn.btn-primary {
    border: none;
    position: relative;
    top: -4px;
    background-color: var(--royal-blue);
    font-weight: 700;
    box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 6px 0 0 #2061b1, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
}

.btn.btn-primary:active {
    box-shadow: none;
    top: 0px;
}

.btn.btn-primary2 {
    background-color: var(--blue);
}

.btn.btn-primary.no-shadow {
    box-shadow: none;
    top: 0px;
}

.btn.btn-outline-secondary {
    background-color: var(--white);
    border: none;
    color: var(--red);
    box-shadow: 0 0 0 1px var(--red) inset, 0 0 0 2px rgba(206, 198, 198, 0.1) inset, 0 6px 0 0 #636262, 0 8px 8px 1px rgb(199 199 199);
    position: relative;
    top: -4px;
}

.btn.btn-outline-secondary:active {
    box-shadow: none;
    top: 0px;
}

.btn.btn-outline-primary {
    background-color: var(--medium-gray);
    border: none;
    color: var(--royal-blue);
    box-shadow: 0 0 0 1px var(--gray) inset, 0 0 0 2px rgba(206, 198, 198, 0.1) inset, 0 6px 0 0 #636262, 0 8px 8px 1px rgb(199 199 199);
    position: relative;
    top: -4px;
}

.btn.btn-outline-primary:active {
    box-shadow: none;
    top: 0px;
}

.btn.btn-secondary {
    box-shadow: 0 0 0 1px #959595 inset, 0 0 0 2px rgba(255, 255, 255, 0.10) inset, 0 6px 0 0 #636262, 0 8px 8px 1px rgb(199 199 199);
    position: relative;
    top: -4px;
}

.btn.btn-secondary:active {
    box-shadow: none;
    top: 0px;
}

.btn.btn-success {
    border-color: #3fdd2f !important;
    border: none;
    box-shadow: 0 0 0 1px #264a0f inset, 0 0 0 2px #85b167 inset, 0 6px 0 0 #264a0f, 0 8px 8px 1px rgb(199 199 199);
    position: relative;
    top: -4px;
}

.btn.btn-success:active {
    box-shadow: none;
    top: 0px;
}

.btn.btn-danger {
    border-color: #dc3545 !important;
    border: none;
    box-shadow: 0 0 0 1px #973b21 inset, 0 0 0 2px #f66154 inset, 0 6px 0 0 #973b21, 0 8px 8px 1px rgb(199 199 199);
    position: relative;
    top: -4px;
}

.btn.btn-danger:active {
    box-shadow: none;
    top: 0px;
}