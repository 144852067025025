.icon svg {
    fill: currentColor;
}

.btn-icon {
    opacity: 0.8;
    border: none;
    background: transparent;
    padding: 0;
}

.btn-icon:disabled,
.btn-icon.disabled {
    opacity: 0.2;
    pointer-events: none;
}

.btn-icon:disabled:hover,
.btn-icon.disabled:hover {
    cursor: not-allowed;
}

.btn-icon:not([disabled]):hover {
    opacity: 1;
    cursor: pointer;
}

.icon-notification svg {
    color: var(--white);
}