@import url('../node_modules/react-quill/dist/quill.snow.css');
@import url('../node_modules/react-datepicker/dist/react-datepicker.min.css');
@import url('./assets/style.css');
@import url('./assets/buttons.css');
@import url('./assets/form.css');
@import url('./assets/modals.css');
@import url('./assets/editor.css');


body {
  margin: 0;
  font-family: 'Montserrat' !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}