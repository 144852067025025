input::placeholder,
textarea::placeholder {
    font-size: var(--font-sm);
    text-transform: initial !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.form-check-input {
    border-color: var(--page-bg);
}

.form-label {
    margin-bottom: 0;
    font-size: var(--font-md);
    color: var(--royal-blue);
    font-weight: 700;
}

.form-group {
    position: relative;
    padding-bottom: 0.825rem;
    margin-bottom: 0;
}

.input-group.has-group-text-right .form-control {
    border-right: none !important;
}

.input-group-text {
    background-color: transparent;
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
}

.form-control,
.form-control:focus {
    box-shadow: none;
    border-color: var(--page-bg);
}

.form-control.is-valid:focus,
.form-control.is-invalid:focus {
    box-shadow: none;
}

.form-control.is-valid,
.form-control.is-valid:focus,
.form-control.is-valid+.input-group-text {
    border-color: var(--emarald-green);
    background-image: none;
    padding-right: 0.75rem;
}

.form-control.is-invalid,
.form-control.is-invalid:focus,
.form-control.is-invalid+.input-group-text {
    border-color: var(--light-red);
    background-image: none;
    padding-right: 0.75rem;
}

.form-group .invalid-feedback {
    position: absolute;
    bottom: -4px;
    font-size: var(--font-sm);
    margin-top: 0;
    line-height: var(--font-sm);
}

.select-control {
    width: 100%;
}

.select-control [class$="-control"],
.select-control [class$="-control"]:hover {
    box-shadow: none;
    border-color: var(--page-bg);
}

.select-control [class$="-indicatorContainer"],
.select-control [class$="-indicatorContainer"]:hover {
    color: var(--blue);
}

.select-control [class*="-indicatorSeparator"] {
    display: none;
}

.select-control.error-field [class$="-control"] {
    border-color: var(--light-red);
}

.input-as-textarea {
    resize: none;
}

.quill.form-control {
    height: 100%;
    padding: 0;
}

.quill.form-control .ql-toolbar,
.quill.form-control .ql-container {
    border: none;
}

.quill.form-control .ql-toolbar {
    border-bottom: 1px solid var(--page-bg);
}

.horizontal-form form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 12px;
}

.horizontal-form .grid-col-100 {
    grid-column-start: 1;
    grid-column-end: 4;
}

.horizontal-form .grid-col-66 {
    grid-column-start: 1;
    grid-column-end: 3;
}

.horizontal-form .form-check {
    min-height: 42px;
}

.horizontal-form.col2 form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 12px;
}

.horizontal-form.col2 .grid-col-100 {
    grid-column-start: 1;
    grid-column-end: 3;
}

.date-picker [class*="-container"],
.month-picker [class*="-container"] {
    height: 38px !important;
}

.date-picker [class*="-container"] input,
.month-picker [class*="-container"] input {
    height: 100% !important;
    margin: 0;
}

.date-picker [class*="-container"] {
    width: 100%;
}

.date-picker [class*="-arrow-container"] {
    width: 38px;
}

.date-picker [class*="-wrapper"] {
    padding-bottom: 0.5rem;
}

.date-picker [class*="-container"] input {
    width: 75% !important;
}

.rmdp-arrow-container {
    justify-content: center;
    width: 38px;
    align-items: center;
    margin: 0;
}

.rmdp-arrow-container .rmdp-arrow {
    margin-top: 0;
    padding: 4px;
}

.filter-label {
    color: var(--royal-blue);
    font-weight: 700;
}