:root {
  --white: #FFFFFF;
  --black: #000000;
  --black-600: rgba(0, 0, 0, 0.6);

  --royal-blue: #2965AD;
  --mid-royal-blue: #3C87C2;
  --blue: #3C93D8;
  --light-blue: #9CC2E5;
  --pale-blue: #DEEFF9;

  --dark-gray: #322C2D;
  --gray: #A5A5A5;
  --mild-gray: #BFBFBF;
  --medium-gray: #CCD0D3;
  --gray-300: rgba(50, 44, 45, 0.3);
  --gray-500: rgba(50, 44, 45, 0.5);
  --gray-800: rgba(50, 44, 45, 0.08);
  --gray-400: rgba(50, 44, 45, 0.04);

  --yellow: #FFC000;

  --red: #FF0000;
  --medium-red: #FF5050;
  --light-red: rgba(220, 53, 69, 0.5);

  --dark-green: #548235;
  --emarald-green: #0D9500;
  --green: #4EC343;
  --medium-green: #92D050;
  --light-green: #A9D18E;

  --orange: #fc8403;

  --page-bg: #a1cbe7;

  --tag-color:#2965AD;
  --tag-text: #fff;

  /* Fonts */
  /* 10px */
  --font-xs: 0.625rem;
  /* 12px */
  --font-sm: 0.75rem;
  /* 14px */
  --font-md: 0.875rem;
  /* 16px */
  --font-base: 1rem;
  /* 20px */
  --font-lg: 1.25rem;
  /* 24px */
  --font-xl: 1.5rem;
  /* 32px */
  --font-xxl: 2rem;
  /* 64px */
  --font-xxxl: 3rem;
}

@media only screen and (max-width: 1024px) {
  :root {
    --font-xs: 0.5rem;
    /* 12px */
    --font-sm: 0.625rem;
    /* 14px */
    --font-md: 0.75rem;
    /* 16px */
    --font-base: 0.875rem;
    /* 20px */
    --font-lg: 1rem;
    /* 24px */
    --font-xl: 1.25rem;
    /* 32px */
    --font-xxl: 1.5rem;
    /* 64px */
    --font-xxxl: 2rem;
  }
}

.text-xs {
  font-size: var(--font-xs);
}

.text-sm {
  font-size: var(--font-sm);
}

.text-md {
  font-size: var(--font-md);
}

.text-lg {
  font-size: var(--font-lg);
}

.text-xl {
  font-size: var(--font-xl);
}

.text-xxl {
  font-size: var(--font-xxl);
}

.text-xxxl {
  font-size: var(--font-xxxl);
}

.text-warn {
  color: var(--yellow);
  fill: var(--yellow);
}

.text-success {
  color: var(--emarald-green);
  fill: var(--emarald-green);
}

.text-error {
  color: var(--red);
  fill: var(--red);
}

.text-dark-gray {
  color: var(--dark-gray);
}

.text-gray-800 {
  color: var(--gray-800);
}

.text-appprimary {
  color: var(--royal-blue) !important;
}

.text-black-600 {
  color: var(--black-600) !important;
}

.text-white {
  color: var(--white) !important;
}

.bg-dashboard {
  background-color: var(--mid-royal-blue);
}

.bg-white {
  background-color: var(--white);
}

.letter-spacing-2 {
  letter-spacing: 0.5rem;
}

a,
link {
  text-decoration: none !important;
}

body {
  background-color: var(--page-bg) !important;
  overflow: hidden !important;
  font-size: var(--font-base);
}

/* overwrite css */

.btn-check:checked+.btn {
  background-color: var(--royal-blue) !important;
  box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 6px 0 0 #2061b1, 0 8px 8px 1px rgba(0, 0, 0, 0.5) !important;
}


.underline {
  text-decoration: underline !important;
}

.userNameL {
  width: 25px;
  height: 25px;
  display: inline-block;
  background: purple;
}

.pageHeading {
  background-color: var(--royal-blue);
  height: 52px;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  justify-content: space-between;
  color: var(--white);
}

.breadcrumb li {
  font-size: 0.875rem;
}

.breadcrumb a {
  color: var(--white);
}

.breadcrumb-item.active {
  color: var(--white);
}

.breadcrumb-item+.breadcrumb-item::before {
  color: var(--white);
}


/* Dashboard-Tabs Start */
.nav-tabs.dashboardTabs {
  border-bottom: 3px solid var(--white);
}

.nav-tabs.dashboardTabs .nav-link {
  border-radius: 25px 25px 0 0;
  background-color: var(--gray);
  color: var(--white);
  padding: 10px 35px;
  font-weight: 500;
}

.nav-tabs.dashboardTabs .nav-link.active {
  background-color: var(--royal-blue);
  color: var(--white);
  border: none;
  border-bottom-width: 2px;
  box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 6px 0 0 #1a406b, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  position: relative;
  top: -2px;
}

/* Dashboard-cards count */

.shadow {
  box-shadow: -4px 8px 8px rgba(0, 0, 0, 0.2) !important;
}

.cardCount {
  height: 60px;
  color: var(--white);
}

.cardCount a {
  width: 25px;
  height: 25px;
  background-color: var(--royal-blue);
  display: inline-block;
  text-align: center;
  color: var(--white);
}


.cardCount.grey {
  border-left: 5px solid var(--gray);
}

.cardCount.grey a {
  background-color: var(--gray);
}

.cardCount.red {
  border-left: 5px solid var(--red);
}

.cardCount.red a {
  background-color: var(--red);
}

.cardCount.yellow {
  border-left: 5px solid var(--yellow);
}

.cardCount.yellow a {
  background-color: var(--yellow);
}

.cardCount.green {
  border-left: 5px solid var(--green);
}

.cardCount.green a {
  background-color: var(--green);
}

.vendorPerformance-cards {
  min-height: 315px;
}

.vendorPerformance-cards .cardCount {
  font-size: .85rem;
}

.vendorPerformance-cards .cardCount h3 {
  font-size: 1.2rem;
}

.vendorPerformance-cards .cardCount a {
  width: 15px;
  height: 15px;
}


.nav-tabs-rounded {
  background-color: var(--gray-800);
  color: var(--dark-gray);
  border-radius: 5px;
  font-size: 1rem;
}

.nav-tabs-rounded .nav-item .nav-link {
  background-color: transparent;
  color: var(--dark-gray);
  border-radius: 5px;
  padding: 5px 10px;
}

.nav-tabs-rounded .nav-item .nav-link.active {
  background-color: var(--blue);
  color: var(--white);
  border: none;
  box-shadow: 0 0 0 1px #1578d9 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 6px 0 0 #3a6f9f, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  position: relative;
  top: -4px;
}

.nav-tabs-rounded .nav-item .nav-link.active:acive {
  top: 0;
  box-shadow: none;
}


.cardList {
  border-top: 5px solid transparent;
  height: 90px;
}

.cardList li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 0;
}

.cardList.red {
  border-top: 5px solid var(--red);
}

.cardList.red span {
  color: var(--red);
}

.cardList.yellow span {
  color: var(--yellow);
}


/* Forms styling */

.form-select {
  border: 1px solid var(--black) !important;
}


/* progress bar prime override  */
.progress {
  background-color: #cdd7dc !important;
}

.progress .progress-bar {
  background-color: #21a7e6 !important;
}

/*vendor dashboard Newupdates */

.bannerSlider .carousel-control-next,
.bannerSlider .carousel-control-prev {
  display: none;
}

.bannerSlider img {
  height: 100vh
}

@media only screen and (max-width: 1024px) {
  .bannerSlider {
    display: none;
  }

  .loginSection {
    width: 100%;
    height: 100vh;
  }
}

.loginSection {
  background-color: var(--white);
  position: relative;
  z-index: 999;
}

.loginSection:before {
  border-left: 16px solid var(--royal-blue);
  background-color: var(--white);
  position: absolute;
  z-index: -1;
  left: -20%;
  top: -10%;
  bottom: -10%;
  right: 0;
  width: 40%;
  content: "";
  transform: rotate(10deg) skewY(-10deg);
}

.fixed_header {
  table-layout: fixed;
  border-collapse: collapse;
}

.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
}

.fixed_header thead tr,
.fixed_header tbody tr {
  display: flex;
}

.error [class*="-control"] {
  border-color: var(--bs-danger);
}

.yellow path {
  fill: var(--yellow);
}

.grey path {
  fill: var(--gray);
}

.green path {
  fill: var(--green);
}

.red path {
  fill: var(--red);
}

.filter-label {
  color: var(--bs-gray-500);
}

.required {
  color: var(--bs-danger);
}

.form-control {
  border-color: hsl(0, 0%, 80%);
}

table.table {
  font-size: 14px;
}

.status-ActivitySaved {
  color: var(--blue);
}

.status-not-applicable,
.status-Not-Applicable {
  color: var(--mild-gray);
}

.status-Pending,
.status-Due {
  color: var(--light-red);
}

.status-Rejected {
  color: var(--yellow)
}

.status-non-compliance,
.status-NonCompliant {
  color: var(--red);
}

.status-Overdue {
  color: var(--orange);
}

.status-Submitted {
  color: var(--light-green);
}

.status-compliant,
.status-Compliant {
  color: var(--medium-green);
}

.status-Approved,
.status-Audited {
  color: var(--light-blue);
}

.filters label {
  padding-left: 0.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.filters [class*="-menu"] {
  min-width: 200px;
}

.filters [class*="-container"] {
  max-width: 200px;
}

.filters [class*="-container"] [class*="-control"] {
  font-size: var(--font-sm);
  box-shadow: none;
  border-color: var(--page-bg);
  width: auto;
  min-width: 150px;
  max-width: 200px;
}

.filters [class*="-container"] [class*="-control"]:hover,
.filters [class*="-container"] [class*="-control"]:focus,
.filters [class*="-container"] [class*="-control"]:active {
  border-color: var(--page-bg);
  box-shadow: none;
}


.filters [class*="-container"] [class*="-control"] [class*="-ValueContainer"] {
  padding: 0 4px;
}

.filters [class*="-container"] [class*="-control"] [class*="-indicatorContainer"] {
  padding: 0 4px;
}

.filters .date-picker [class*="-container"] input {
  width: 100% !important;
  font-size: var(--font-sm);
}

.modal-dialog.modal-lg.lg-mini {
  max-width: 600px;
}

*::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.5rem transparent;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--gray-500);
  border-radius: 5px;
}

.w-33 {
  width: 33% !important;
}

.bg-compliance-status-Due {
  background-color: var(--gray) !important;
  box-shadow: 0 0 0 1px #77797b inset, 0 0 0 2px rgba(255, 255, 255, 0.10) inset, 0 6px 0 0 #717477, 0 8px 8px 1px rgb(199 199 199);
}

.bg-compliance-status-NonCompliant {
  background-color: var(--medium-red) !important;
  box-shadow: 0 0 0 1px #e10a0a inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 6px 0 0 #e35252, 0 8px 8px 1px rgb(199 199 199);
}

.bg-compliance-status-Rejected {
  background-color: var(--red) !important;
  box-shadow: 0 0 0 1px #e10a0a inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 6px 0 0 #e35252, 0 8px 8px 1px rgb(199 199 199);
}

.bg-status-ActivitySaved {
  background-color: var(--blue) !important;
  box-shadow: 0 0 0 1px #004895 inset, 0 0 0 2px rgba(255, 255, 255, 0.10) inset, 0 6px 0 0 #3875a1, 0 8px 8px 1px rgb(199 199 199);
}

.bg-status-Pending {
  background-color: var(--light-red) !important;
  box-shadow: 0 0 0 1px #f36666 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 6px 0 0 #ff7676, 0 8px 8px 1px rgb(199 199 199);
}

.bg-status-Overdue {
  background-color: var(--orange) !important;
  box-shadow: 0 0 0 1px #d79a47 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 6px 0 0 #D79A34, 0 8px 8px 1px rgb(199 199 199);
}

.bg-compliance-status-Submitted,
.bg-compliance-status-Pending,
.bg-status-Rejected {
  background-color: var(--yellow) !important;
  box-shadow: 0 0 0 1px #d79a47 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 6px 0 0 #D79A34, 0 8px 8px 1px rgb(199 199 199);
}

.bg-status-Submitted {
  background-color: var(--light-green);
  box-shadow: 0 0 0 1px #52c92a inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 6px 0 0 #6b9d4b, 0 8px 8px 1px rgb(199 199 199);
}

.bg-compliance-status-Approved,
.bg-status-Approved,
.bg-status-Audited {
  background-color: var(--light-blue) !important;
  box-shadow: 0 0 0 1px #41839d inset, 0 0 0 2px rgba(255, 255, 255, 0.10) inset, 0 6px 0 0 #4991b3, 0 8px 8px 1px rgb(199 199 199);
}

/* .status-btn-group input[name="Overdue"]+.btn,
.status-btn-group input[name="Rejected"]+.btn {
  background-color: var(--red);
  box-shadow: 0 0 0 1px #870101 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 6px 0 0 #8b3f3f, 0 8px 8px 1px rgb(199 199 199);
}

.status-btn-group input[name="Submitted"]+.btn {
  background-color: var(--light-green);
  box-shadow: 0 0 0 1px #52c92a inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 6px 0 0 #6b9d4b, 0 8px 8px 1px rgb(199 199 199);
}

.status-btn-group input[name="Approved"]+.btn {
  background-color: var(--dark-green);
  box-shadow: 0 0 0 1px #2f7119 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 6px 0 0 #3d6f1d, 0 8px 8px 1px rgb(199 199 199);
} */