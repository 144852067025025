.main-conatiner {
    display: grid;
    grid-template-rows: 84px 1fr;
    height: 100vh;
    overflow: auto;
}


.page-layout-container {
    display: flex;
    flex-direction: row;
    /* height: 100vh; */
    overflow: hidden;
    align-items: flex-start;
    /* padding-top: 84px; */
    min-width: 1280px;
}

.sidenav-container {
    width: 64px;
    transition: width 0.3s;
    height: calc(100vh - 84px);
    background: var(--white);
    padding: 0 !important;
    overflow: hidden;
    overflow-y: auto;
    border-right: 1px solid var(--bs-border-color);
    border-top: 1px solid var(--bs-border-color);
}

.sidenav-container .sidenav-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 44px;
}

.sidenav-container .sidenav-item-label {
    display: none;
    align-items: center;
    white-space: nowrap;
}

.main-container {
    width: calc(100% - 64px);
    overflow: auto;
    transition: width 0.3s;
    height: calc(100vh - 84px);
}

.sidenav-container.open {
    width: 264px;
}

.sidenav-container.open .sidenav-item-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    text-align: left;
    padding-right: 6px;
}

.sidenav-container.open .sidenav-item-icon {
    width: 44px;
    min-width: 44px;
}

.sidenav-container.open+.main-container {
    width: calc(100% - 264px);
}