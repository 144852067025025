.previewOverlay {
    position: absolute;
    z-index: 1111;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.5);
}

.previewOverlay .closeBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.previewOverlay iframe {
    display: block;
    border: none;
    height: 100vh;
    width: 100vw;
    max-width: 800px;
}